import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Container, Row, Col } from '../ui/Wrapper';
import Heading from '../ui/Heading';
import TeamMember from './TeacherItem';
import { TeamWrapper } from './styles';

const TeachersList = ({ itemsFilter, headingStyle, headTeamRowStyle }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulTeacher(filter: { active: { eq: true }, availableOn: { eq: "Mais Militar" } }) {
        nodes {
          id
          slug
          name
          description {
            description
          }
          image {
            fluid(maxWidth: 270, maxHeight: 330, quality: 100, toFormat: WEBP) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  `);

  const teachers = data.allContentfulTeacher.nodes;

  const sortTeachers = (a, b) => {
    if (!itemsFilter || itemsFilter.length === 0) {
      return true;
    }
    return itemsFilter.indexOf(a.slug) - itemsFilter.indexOf(b.slug);
  };

  const filterTeachers = teacher => {
    if (!itemsFilter || itemsFilter.length === 0) {
      return true;
    }
    return itemsFilter.includes(teacher.slug);
  };

  return (
    <TeamWrapper id="professores">
      <Container>
        <Row>
          <Col lg={12}>
            <Heading {...headingStyle} noBind>
              Conheça os <span>professores</span> deste curso
            </Heading>
          </Col>
        </Row>
        <Row {...headTeamRowStyle} className="justify-content-center">
          {teachers
            .sort(sortTeachers)
            .filter(filterTeachers)
            .map(teacher => (
              <Col md={4} key={teacher.id}>
                <TeamMember image={teacher.image} name={teacher.name} description={teacher.description.description} />
              </Col>
            ))}
        </Row>
      </Container>
    </TeamWrapper>
  );
};

TeachersList.propTypes = {
  itemsFilter: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      image: PropTypes.object.isRequired,
      description: PropTypes.string,
    })
  ).isRequired,
  headingStyle: PropTypes.object,
  headTeamRowStyle: PropTypes.object,
};

TeachersList.defaultProps = {
  headingStyle: {
    as: 'h3',
    fontSize: '40px',
    lineHeight: 1.4,
    textalign: 'center',
    mb: '85px',
    child: {
      color: 'primary',
    },
    responsive: {
      large: {
        fontSize: '34px',
        mb: '70px',
      },
      medium: {
        fontSize: '28px',
        mb: '50px',
      },
    },
  },
  headTeamRowStyle: {
    mb: '36px',
    gutters: {
      lg: 60,
    },
    responsive: {
      medium: {
        mb: '26px',
      },
      small: {
        mb: 0,
      },
    },
  },
};

export default TeachersList;
