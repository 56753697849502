import React, { Fragment, useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Image from '../Image';
import { Container, Row, Col } from '../ui/Wrapper';
import SectionTitle from '../ui/section-title';
import VideoButton from '../ui/video-button';
import ModalVideo from '../ui/modal-video';
import Button from '../ui/button';
import {
  AboutAreaWrap,
  ImageBox1,
  ImageBox2,
  ImageBox3,
  ImageBox4,
  SectionBottom,
  MainImageBox,
  VideoBtnWrap,
  TextBox1,
  AboutTextBox2,
  AboutImageBox,
  AboutTextBoxAnimation,
  TextBox2,
  TextBox3,
  TextBox4,
} from './styles';
import useSiteLocation from '../../hooks/useSiteLocation';

const DemonstrationVideo = ({
  sectionTitleStyle,
  buttonOneStyle,
  buttonTwoStyle,
  title,
  subtitle,
  action,
  secondaryAction,
  backgroundImage,
  videoItems,
  noPadding,
}) => {
  const { buildPath } = useSiteLocation();
  const [videoOpen, setVideoOpen] = useState(false);
  const modalVideoOpen = () => {
    setVideoOpen(true);
  };

  const modalVideoClose = () => {
    setVideoOpen(false);
  };

  const [, videoId] = videoItems.videoLink.split('=', -1);
  const [, videoChannel] = videoItems.videoLink.split('.');

  return (
    <>
      <AboutAreaWrap id="demo-video" noPadding={noPadding}>
        <Container>
          <Row>
            <Col lg={12}>
              <SectionTitle {...sectionTitleStyle} subtitle={subtitle} title={title} />
            </Col>
          </Row>
          <Row alignitems="center" className="justify-content-center">
            <Col lg={12}>
              <AboutTextBoxAnimation>
                <TextBox1 className="animation_text one">DEDICAÇÃO</TextBox1>
                <TextBox2 className="animation_text two">DETERMINAÇÃO</TextBox2>
                <MainImageBox>
                  <Image fluid={backgroundImage} alt="Play" />
                  <VideoBtnWrap>
                    <VideoButton skin="primary" onClick={modalVideoOpen} />
                  </VideoBtnWrap>
                </MainImageBox>
                <ModalVideo channel={videoChannel} videoId={videoId} isOpen={videoOpen} onClose={modalVideoClose} />
                <TextBox3 className="animation_text three">DISCIPLINA</TextBox3>
                <TextBox4 className="animation_text four">CORAGEM</TextBox4>
              </AboutTextBoxAnimation>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <SectionBottom className="text-center">
                {action && (
                  <Button as={Link} to={buildPath(action.link)} target="_blank" {...buttonOneStyle}>
                    {action.label}
                  </Button>
                )}
                {secondaryAction && (
                  <Button as={Link} to={secondaryAction.link} target="_blank" {...buttonTwoStyle}>
                    {secondaryAction.label}
                  </Button>
                )}
              </SectionBottom>
            </Col>
          </Row>
        </Container>
      </AboutAreaWrap>
    </>
  );
};

DemonstrationVideo.propTypes = {
  sectionTitleStyle: PropTypes.object,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  action: PropTypes.shape({
    label: PropTypes.string,
    link: PropTypes.string,
  }),
  secondaryAction: PropTypes.shape({
    label: PropTypes.string,
    link: PropTypes.string,
  }),
  backgroundImage: PropTypes.object.isRequired,
  videoItems: PropTypes.shape({
    videoLink: PropTypes.string,
  }).isRequired,
  noPadding: PropTypes.bool,
  buttonOneStyle: PropTypes.object,
  buttonTwoStyle: PropTypes.object,
};

DemonstrationVideo.defaultProps = {
  subtitle: null,
  action: null,
  secondaryAction: null,
  sectionTitleStyle: {
    mb: '40px',
    responsive: {
      small: {
        mb: '30px',
      },
    },
  },
  noPadding: false,
  buttonOneStyle: {
    m: '10px',
    hover: '2',
  },
  buttonTwoStyle: {
    m: '10px',
    varient: 'outlined',
  },
};

export default DemonstrationVideo;
