import { graphql, useStaticQuery } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import PuffLoader from 'react-spinners/PuffLoader';
import PageHeader from '../pageheader';
import SEO from '../seo';
import Footer from '../../containers/Layout/Footer';
import Header from '../../containers/Layout/Header';
import Text from '../ui/Text';
import Anchor from '../ui/Anchor';
import Form, { FormGroup, Input, Radio, Select } from '../ui/form';
import { Container, Row, Col } from '../ui/Wrapper';
import { CaseStudyWrapper } from '../../containers/courses/courses.style';
import CaseStudyBox from '../box-large-image/layout-two';
import productsService from '../../services/productsService';
import { ButtonWrap, ButtonGroupWrap } from '../ui/button/button.style';
import { RadioContainer } from '../ResponsibleRadio/styles';
import Label from '../ui/form/label/label';
import Alert from '../ui/alert';
import SectionTitle from '../ui/section-title';
import { LoadingContainer } from '../../containers/site-selection/styles';
import { theme } from '../../theme';
import { ItemProduct } from './styles';

const ProductWP = ({
  location,
  pageContext,
  sectionTitleStyle,
  bottomTextStyle,
  bottomTextLinkStyle,
  caseStudyStyles,
  tag,
  title,
  subtitle,
}) => {
  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "images/bg/camouflaged2.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
            presentationHeight
          }
        }
      }
    }
  `);

  const { backgroundImage } = data;

  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [categorie, setCategorie] = useState({});

  const handleChangeCourse = event => {
    setCategorie({ name: event.target.value });
  };

  useEffect(() => {
    productsService.productsByTag(tag).then(response => {
      setProducts([...response.data]);
      setLoading(false);
    });
  }, []);

  const imageData = backgroundImage.childImageSharp.fluid;

  return (
    <>
      <CaseStudyWrapper id="produtos" fluid={imageData}>
        <Container>
          <Row>
            <Col lg={12}>
              <SectionTitle {...sectionTitleStyle} subtitle={subtitle} title={title} />
            </Col>
          </Row>
          <Row alignitems="center" className="justify-content-center">
            {loading && (
              <Col lg={12}>
                <LoadingContainer>
                  <PuffLoader color={theme.colors.primary} size={60} />
                </LoadingContainer>
              </Col>
            )}
            {!loading && products.length === 0 && (
              <Col lg={12}>
                <Alert align="center" variant="warning">
                  <>
                    <Text mb="0" fontWeight="bold">
                      Não existem cursos para a categoria selecionada
                    </Text>
                  </>
                </Alert>
              </Col>
            )}
            {products.map(product => (
              <Col lg={4}>
                <>
                  <ItemProduct className="item" key={product.slug}>
                    <CaseStudyBox
                      {...caseStudyStyles}
                      imageSrc={product.images[0]?.src}
                      title={product.name}
                      category={product.categories[0]?.name}
                      desc={product.price_html}
                      path={product.permalink}
                      btnText="Saiba mais"
                    />
                  </ItemProduct>
                </>
              </Col>
            ))}
          </Row>
        </Container>
      </CaseStudyWrapper>
    </>
  );
};

ProductWP.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  sectionTitleStyle: PropTypes.object,
  bottomTextStyle: PropTypes.object,
  bottomTextLinkStyle: PropTypes.object,
  caseStudyStyles: PropTypes.object,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  tag: PropTypes.object.isRequired,
};

ProductWP.defaultProps = {
  sectionTitleStyle: {
    mb: '40px',
    responsive: {
      small: {
        mb: '30px',
      },
    },
  },
  bottomTextStyle: {
    fontSize: '18px',
    fontweight: 500,
    lineHeight: 1.4,
    color: '#333333',
    mt: '40px',
    align: 'center',
  },
  bottomTextLinkStyle: {
    fontWeight: 500,
    layout: 'underline',
    hover: {
      layout: 2,
    },
  },
  caseStudyStyles: {
    boxStyle: {
      mt: '20px',
      mb: '35px',
      ml: '15px',
      mr: '15px',
    },
    contentBoxStyle: {
      pt: '25px',
      pl: '26px',
      pr: '26px',
      textalign: 'left',
    },
    headingStyle: {
      as: 'h6',
      fontweight: 600,
      mb: '2px',
    },
    descStyle: {
      mb: 0,
      mt: '13px',
    },
  },
};
export default ProductWP;
