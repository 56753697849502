import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import parse from 'html-react-parser';
import { Container, Row, Col } from '../ui/Wrapper';
import SectionTitle from '../ui/section-title';
import AccordionWrap from '../ui/accordion';
import {
  AboutAreaWrap,
  AboutTextBox,
  AboutImageBox,
  ImageBox1,
  ImageBox2,
  ImageBox3,
  ImageBox4,
  MainImageBox,
  VideoBtnWrap,
  AccordionItemText,
} from './styles';

const PreparationCourseFaq = ({ sectionTitleStyle, title, subtitle, items, noPadding }) => {
  return (
    <>
      <AboutAreaWrap id="duvidas-frequentes" noPadding={noPadding}>
        <Container>
          <Row alignitems="center">
            <Col lg={12}>
              <AboutTextBox>
                <SectionTitle {...sectionTitleStyle} title={title} subtitle={subtitle} />
                <AccordionWrap>
                  <Accordion allowZeroExpanded preExpanded={[0]}>
                    {items.map((el, index) => {
                      return (
                        <AccordionItem id={el.id} key={index}>
                          <AccordionItemHeading>
                            <AccordionItemButton>{el.title}</AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <AccordionItemText dangerouslySetInnerHTML={{ __html: el.content.content }} />
                          </AccordionItemPanel>
                        </AccordionItem>
                      );
                    })}
                  </Accordion>
                </AccordionWrap>
              </AboutTextBox>
            </Col>
          </Row>
        </Container>
      </AboutAreaWrap>
    </>
  );
};

PreparationCourseFaq.propTypes = {
  sectionTitleStyle: PropTypes.object,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
    })
  ).isRequired,
  noPadding: PropTypes.bool,
};

PreparationCourseFaq.defaultProps = {
  subtitle: null,
  sectionTitleStyle: {
    mb: '40px',
    responsive: {
      small: {
        mb: '30px',
      },
    },
    align: 'center',
  },
  noPadding: false,
};

export default PreparationCourseFaq;
